import {
  collection,
  doc,
  getDocFromCache,
  getDocFromServer,
  getDocs,
  getFirestore,
  query,
  where,
  writeBatch,
} from '@firebase/firestore';
import { v4 } from 'uuid';
import { UUID } from 'src/@types/common';
import { constructPath, getPathForCanvasContent } from '../paths';
import { UnknownPartial } from '../types';
import { getCanvasContentConverter } from './CanvasContent.converter';
import { CanvasContentDto } from './dto/canvasContent.dto';
import { CanvasContentCreateDto } from './dto/canvasContentCreate.dto';

/**
 * Used for creating a complete {@link CanvasContentDto} with the default values.
 * @param dto - Template for creating a canvas content document
 * @returns A complete canvas content document
 */
export function prepareCanvasContentDto<
  TContent extends UnknownPartial = UnknownPartial,
>(dto: CanvasContentCreateDto<TContent>) {
  return new CanvasContentDto<TContent>({
    content: dto.content,
    contentType: dto.contentType,
    type: dto.contentType,
    createdAt: Date.now(),
    createdBy: dto.createdBy,
    editedAt: Date.now(),
    editedBy: [dto.createdBy],
    flowInstanceId: dto.flowInstanceId,
    id: dto.id ?? v4(),
    semanticId: dto.semanticId,
    sessionId: dto.sessionId,
    isContentEditable: dto.isContentEditable ?? false,
    isMovable: dto.isMovable ?? false,
    isSelectedOutcome: false,
    lastMovedAt: dto.parentNodes && (dto.x || dto.y) ? Date.now() : undefined,
    parentNodes: dto.parentNodes,
    x: dto.x,
    y: dto.y,
  });
}

/**
 * @param docId - The canvas content ID
 * @param collectionPath - Custom Firestore path of the created document.
 * @returns Firestore reference to be used as a pointer in e.g. `getDoc()`
 */
export function getCanvasContentDocRef<
  TContent extends UnknownPartial = UnknownPartial,
>(docId: UUID, collectionPath?: string) {
  const converter = getCanvasContentConverter<TContent>();
  return doc(
    getFirestore(),
    collectionPath
      ? constructPath(collectionPath, docId)
      : getPathForCanvasContent(docId),
  ).withConverter(converter);
}

/**
 * Tries fetching a canvas content document primarily from the cache.
 * If the document isn't cached, then the database is called explicitly.
 *
 * This util complements Firestore's `getDoc()` that tries reaching out to the server,
 * which might make some operations unnecessarily slower.
 * @param docId - Canvas content ID
 * @param collectionPath - Custom Firestore path of the created document.
 * @returns Document snapshot
 */
export async function getCanvasContentDocCached<
  TContent extends UnknownPartial = UnknownPartial,
>(docId: UUID, collectionPath?: string) {
  const ref = getCanvasContentDocRef<TContent>(docId, collectionPath);
  const cachedDoc = await getDocFromCache(ref);
  return cachedDoc.exists() ? cachedDoc : await getDocFromServer(ref);
}

// TODO lift up to be a common util. Both canvas content and widgets use the same DTOs and logic.
/**
 * Checks if the moved item is a drop zone and if it can be dropped
 * in another drop zone according to their parent nodes.
 *
 * If the drop zone's new parent is the child of the same drop zone, the drop action shouldn't be performed.
 * (Implementing `canDrop` in `<BasicDropZone />` didn't work reliably.)
 * @param movedItemId - ID of the moved drop zone.
 * @param nextParentId - ID of the canvas item that becomes the next parent of the moved drop zone.
 * @param collectionPath - Custom Firestore path of the created document.
 * @returns `true` if the next canvas item would be a valid parent node for the drop zone.
 */
export async function isNextParentValidForDropZone(
  movedItemId: UUID,
  nextParentId: UUID,
  collectionPath?: string,
) {
  const nextParentDoc = await getCanvasContentDocCached(
    nextParentId,
    collectionPath,
  );
  const parentsOfNextParent = nextParentDoc.data()?.parentNodes;

  // Pseudo drop zones such as 'ROOT' won't exist
  if (nextParentDoc.exists() && parentsOfNextParent) {
    const isParadoxicalChain = parentsOfNextParent.includes(movedItemId);
    return !isParadoxicalChain;
  }

  // Allow moving positioned items under any future non-positioned canvas content.
  return true;
}

// TODO lift up to be a common util. Both canvas content and widgets use the same DTOs and logic.
/**
 * Checks if the moved canvas item is a drop zone and if it has any children.
 * If yes, the canvas item's updated parent nodes are appended to the parent nodes of all children
 * in a single transaction.
 * @param movedItemId - ID of the moved drop zone.
 * @param nextParentNodesOfParent - Parent nodes to be appended to all children of the moved drop zone.
 * @param collectionPath - Custom Firestore path of the created document.
 */
export async function updateParentNodesOfChildren(
  movedItemId: UUID,
  nextParentNodesOfParent: UUID[],
  collectionPath?: string,
) {
  const parentNodesFilter: keyof CanvasContentDto = 'parentNodes';
  // Not inside a transaction because it would skip the cache even if it was available.
  const childrenOfMovedItem = await getDocs(
    query(
      collection(
        getFirestore(),
        collectionPath ?? getPathForCanvasContent(),
      ).withConverter(getCanvasContentConverter()),
      where(parentNodesFilter, 'array-contains', movedItemId),
    ),
  );

  if (childrenOfMovedItem.docs.length > 0) {
    const batch = writeBatch(getFirestore());
    childrenOfMovedItem.docs.forEach((childItem) => {
      const parentNodesOfChild = childItem.data().parentNodes;
      const parentIndexInChildAncestry =
        parentNodesOfChild?.indexOf(movedItemId) ?? -1;

      // If child of the moved item, cut off the obsolete part of the nodes,
      // and append the latest nodes of the parent.
      if (parentNodesOfChild && parentIndexInChildAncestry > -1) {
        const nextAncestry = parentNodesOfChild
          .slice(0, parentIndexInChildAncestry + 1)
          // eslint-disable-next-line unicorn/prefer-spread
          .concat(nextParentNodesOfParent);
        const payload: Partial<CanvasContentDto> = {
          parentNodes: nextAncestry,
        };
        // Write batches do NOT use the converter assigned to the document reference!
        const payloadConverted = childItem.ref.converter!.toFirestore(payload, {
          merge: true,
        });
        batch.set(childItem.ref, payloadConverted);
      }
    });
    batch.commit();
  }
}
