import { doc, getFirestore } from '@firebase/firestore';
import { UUID } from 'src/@types/common';
import { joinPath } from 'src/utils/path';
import { getPathForCanvasWidget } from '../paths';
import { UnknownPartial } from '../types';
import { getCanvasWidgetConverter } from './CanvasWidget.converter';

/**
 * @param docId - The canvas widget ID
 * @param customCollectionPath - If set, the document will be saved on this path instead of the default collection.
 * @returns Firestore reference to be used as a pointer in e.g. `getDoc()`
 */
export function getCanvasWidgetDocRef<TContent extends UnknownPartial>(
  docId: UUID,
  customCollectionPath?: string,
) {
  const converter = getCanvasWidgetConverter<TContent>();
  return doc(
    getFirestore(),
    customCollectionPath
      ? joinPath(customCollectionPath, docId)
      : getPathForCanvasWidget(docId),
  ).withConverter(converter);
}
