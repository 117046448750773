/** Product name to be used in Resource IDs. */
export const SYSTEM_PRODUCT_NAME = 'superflow';

/**
 * Fake collection name for the "system user", used in Resource IDs where the
 * actor is the system itself.
 */
export const SYSTEM_USER_COLLECTION = 'users';

/**
 * Resource ID for the "system user", used in Resource IDs where the actor is
 * the system itself.
 */
export const SYSTEM_USER_RESOURCE_ID = `${SYSTEM_PRODUCT_NAME}/system`;

/**
 * Collection paths for the Firestore database.
 */
export enum FIRESTORE_COLLECTION_PATH {
  Enrollments = 'enrollments',
  FlowDefinitions = 'flowDefinitions',
  Sessions = 'sessions',
  Workspaces = 'workspaces',
  WorkspaceInvitations = 'workspaceInvitations',
  FlowInstances = 'flowInstances',
  CanvasContent = 'canvasContent',
  CanvasWidget = 'canvasWidget',
  FocusManagerLocks = 'focusManagerLocks',
  SharedResources = 'sharedResources',
  SurveyAnswers = 'surveyAnswers',
  UsageAllocations = 'usageAllocations',
  AuditLogs = 'auditLogs',
}
