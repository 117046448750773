import { CareerDriverSessionType } from 'src/flows/career-drivers/types';
import { DesignSprintSessionType } from 'src/flows/design-sprint-with-ai/types';
import { HPTSessionType } from 'src/flows/high-performing-teams/types';
import { OpeningHeadOfProductSessionType } from 'src/flows/opening-head-of-product/types';
import { UUID } from './common';

/**
 * A union of all possible hand-made session types known by the app.
 * Describes the behavior of the whole session,
 * including the nudges and the canvas background.
 *
 * If its value is `"regular"`, then the UI should load the new drag-drop feature.
 */
export type SessionType =
  | CareerDriverSessionType
  | HPTSessionType
  | DesignSprintSessionType
  | OpeningHeadOfProductSessionType
  | 'regular';

/**
 * Aggregates the current user's progress within a flow instance
 * by calculating completion rates for each included session.
 *
 * Sessions are keyed by the **content definition ID**,
 * because user progress typically needs to be mapped to a session definition on the UI.
 *
 * Module-level calculations are also included to serve UI use cases.
 *
 * Progress rates are represented as fractional values between `0-1`.
 */
export type SessionProgressReport = {
  modules: Record<UUID, number>;
  sessions: Record<UUID, { sessionId: UUID; progress: number }>;
};

/**
 * Defines a preset icon, which will be
 * displayed in the flow view for a session.
 * Should match file names in `src/app/icons/sessionIcons`.
 */
export enum SessionIconId {
  Books = 'books',
  Crosshair = 'crosshair',
  Masonry = 'masonry',
}

// TODO move to component folder types after Redux session is replaced with context
/** Describes the session footer's layout variation. */
export type SessionFooterLayout =
  | 'expanded'
  | 'compact'
  | 'mobileLarge'
  | 'mobileSmall';

//#region  =========== Sidebar ===========

export enum SidebarPage {
  /**
   * Page displaying the outcomes from another canvas.
   */
  PreviousOutcomes = 'PreviousOutcomes',
  /**
   * Page where the user can select outcome items from the current session.
   */
  CurrentOutcomes = 'CurrentOutcomes',
  /**
   * This value should be used for the intent to programmatically close
   * any sidebar page that might be open during a session activity.
   */
  _CloseAny = '_CloseAny',
}

export type SidebarAnimationState = 'hide' | 'hiding' | 'show' | 'showing';

//#endregion  ======== Sidebar ===========

//#region  =========== Facilitation ===========

export interface ISessionModalProps<FormData = unknown> {
  onClose: () => void;
  onSubmit: (formData: FormData) => void;
}

//#endregion  ======== Facilitation ===========
