import {
  IsBoolean,
  IsEnum,
  IsNumber,
  IsObject,
  IsOptional,
  IsString,
} from 'class-validator';
import { CanvasContentType } from '@features/canvas/components/canvasComponent.types';
import { DragDropManager } from '@features/canvas/services/DragDropManager';
import { UUID } from 'src/@types/common';
import { UnknownPartial } from '../../types';
import {
  IsDraggableType,
  IsFirestoreUUID,
  IsUUIDArray,
} from '../../validators/validators';

export class CanvasContentCreateDto<
  TContent extends UnknownPartial = UnknownPartial,
> {
  /** The item's unique ID. If not set, it will be generated. */
  @IsOptional()
  @IsFirestoreUUID()
  id?: UUID;

  /**
   * A static ID that can be used for facilitation logic, e.g. highlighting the element, or scrolling to it.
   * It circumvents the non-determinism of the `id` prop.
   */
  @IsOptional()
  @IsFirestoreUUID()
  semanticId?: UUID;

  /** ID of the flow instance where the canvas content was created. */
  @IsFirestoreUUID()
  flowInstanceId: UUID;

  /** ID of the canvas session where the canvas content was created. */
  @IsFirestoreUUID()
  sessionId: UUID;

  /**
   * Custom item content. Its format will depend on the component's use case.
   * E.g. a canvas note with custom background colors will have different props than a simple embedded date-picker.
   */
  @IsObject()
  content: TContent;

  /**
   * Describes the type of data stored in the `content` prop.
   * This can be used e.g. to selectively run a type assertion function on the content value.
   */
  @IsEnum(CanvasContentType)
  contentType: CanvasContentType;

  /**
   * Legacy prop kept for compatibility.
   * @deprecated Use `contentType`.
   */
  @IsString()
  type: string;

  /** ID of user who created this content. */
  @IsFirestoreUUID()
  createdBy: UUID;

  /**
   * This type determines whether the rendered item is draggable,
   * or if it acts as a drop zone that accepts other draggable or droppable items.
   */
  @IsOptional()
  @IsDraggableType()
  draggableType?: DragDropManager.Drag.DraggableType;

  /**
   * TODO Not implemented yet
   * Whether the user can update the item's _content_ (e.g. the cell of a table) in the database.
   * Examples: the content of a table cell; the assigned scores saved in a scoring grid.
   * @default true
   */
  @IsOptional()
  @IsBoolean()
  isContentEditable?: boolean;

  /**
   * TODO Not implemented yet
   * Whether the user can update the item's coordinates or parent nodes in the database.
   * @default true
   */
  @IsOptional()
  @IsBoolean()
  isMovable?: boolean;

  /**
   * `true` if the user has selected this canvas content as an outcome item of a session.
   *
   * This boolean is flipped simultaneously when the `SessionOutcome` item is created or deleted.
   * Likewise, when this canvas content is deleted, its associated outcome item is also deleted.
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  isSelectedOutcome?: boolean;

  /**
   * Ancestry tree of the draggable item that contains the IDs of all its parents,
   * starting from its immediate parent up to the root drop zone.
   *
   * Its main use case is so draggable children can hide the original component
   * not only when they are dragged, but also when their parent drop zone is dragged.
   */
  @IsOptional()
  @IsUUIDArray()
  parentNodes?: UUID[];

  /** Item's X coordinate in `px`, if the content can be positioned. */
  @IsOptional()
  @IsNumber()
  x?: number;

  /** Item's Y coordinate in `px`, if the content can be positioned. */
  @IsOptional()
  @IsNumber()
  y?: number;

  constructor(dto: CanvasContentCreateDto<TContent>) {
    this.content = dto.content;
    this.contentType = dto.contentType;
    this.type = dto.contentType;
    this.createdBy = dto.createdBy;
    this.draggableType = dto.draggableType;
    this.flowInstanceId = dto.flowInstanceId;
    this.id = dto.id;
    this.isContentEditable = dto.isContentEditable ?? true;
    this.isMovable = dto.isMovable ?? true;
    this.isSelectedOutcome = dto.isSelectedOutcome ?? false;
    this.parentNodes = dto.parentNodes;
    this.semanticId = dto.semanticId;
    this.sessionId = dto.sessionId;
    this.x = dto.x;
    this.y = dto.y;
  }
}
