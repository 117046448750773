/**
 * Join path segments into one path.
 * @param segments Path segments.
 * @returns Joined path.
 */
export function joinPath(...segments: string[]): string {
  return segments
    .map((segment) => segment.replaceAll(/(^\/|\/$)/g, ''))
    .join('/');
}
