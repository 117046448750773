import * as React from 'react';

export default function FloatingDebug(
  props: React.PropsWithChildren<{ style?: React.CSSProperties }>,
) {
  return (
    <pre
      style={{
        position: 'absolute',
        top: '100%',
        background: 'white',
        pointerEvents: 'none',
        ...props.style,
      }}
    >
      {props.children}
    </pre>
  );
}
