import {
  collection,
  doc,
  documentId,
  getDoc,
  getFirestore,
  orderBy,
  query,
  QueryConstraint,
  setDoc,
  where,
} from '@firebase/firestore';
import mergeWith from 'lodash.mergewith';
import { useMemo, useRef, useSyncExternalStore } from 'react';
import { UUID } from 'src/@types/common';
import { FlowDefinitionDto } from 'src/services/database/FlowDefinitions/dto/flowDefinition.dto';
import {
  CollectionQueryArgs,
  FirestoreFilterArg,
} from 'src/services/database/types';
import { useFirestoreQuerySubscription } from 'src/services/database/useFirestoreQuerySubscription';
import {
  catchFirestoreError,
  createValidatorConverter,
  useMemoizedQueryArgs,
} from 'src/services/database/utils';
import { trace } from 'src/services/telemetry';
import { getPathForFlowDefinitions } from '../paths';
import { useFirestoreDocSubscription } from '../useFirestoreDocSubscription';
import { FlowDefinitionUpdateDto } from './dto/flowDefinitionUpdate.dto';

/**
 * This should be passed to a document subscription to properly convert data to/from Firestore.
 *
 * Later a factory function could be created for these converters
 * that require type assertion functions to be present.
 */
export const FlowDefinitionConverter = createValidatorConverter(
  FlowDefinitionDto,
  { useFirestoreTimestamp: true },
);

/**
 * Subscribes to a single flow document, so changes are synced live.
 * @param flowDefinitionId - The hook won't subscribe to Firestore as long as this value is undefined (e.g. during a 1st render).
 * @returns  High-level meta data for a flow definition and an optional error object.
 */
export function useFlowDefinitionDoc(flowDefinitionId: UUID) {
  // Query must be memoized to avoid infinite re-renders.
  const query = useMemo(
    () =>
      flowDefinitionId
        ? doc(getFirestore(), getPathForFlowDefinitions(flowDefinitionId))
        : undefined,
    [flowDefinitionId],
  );
  const store = useFirestoreDocSubscription(FlowDefinitionConverter, query);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return {
    result,
    error: store.error,
  };
}

/**
 * Subscribes to a collection of flow definitions. The collection can be filtered and sorted,
 * and the results are updated live.
 *
 * Firestore rules are in place to ensure workspace access control, but regardless,
 * this function is mainly for internal use. See {@link useFlowDefinitionList} for a more user-friendly API.
 *
 * **Flow folder names must match the `slug` value**,
 * otherwise the flow adapter won't be found!
 * @returns  Array of flow definitions and an optional error object.
 */
export function useFlowDefinitionCol(
  queryArg?: CollectionQueryArgs<Partial<FlowDefinitionDto>>,
) {
  const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);
  const filterQuery = useMemo(() => {
    const constraints: QueryConstraint[] = [];

    if (filterByIds && filterByIds.length > 0) {
      // Firebase throws when filtering for 'in' with an empty array
      constraints.push(where(documentId(), 'in', filterByIds));
    }
    if (filterBy) {
      filterBy
        .filter(
          function noUndefinedFilterCriteria(
            condition,
          ): condition is FirestoreFilterArg<Partial<FlowDefinitionDto>> {
            return condition?.value !== undefined;
          },
        )
        .forEach((condition) => {
          constraints.push(where(condition.key, condition.op, condition.value));
        });
    }
    if (sort) {
      constraints.push(orderBy(sort.key, sort.order));
    }
    return query(
      collection(getFirestore(), getPathForFlowDefinitions()),
      ...constraints,
    );
  }, [filterBy, filterByIds, sort]);

  const converter = useRef(FlowDefinitionConverter);
  const store = useFirestoreQuerySubscription(converter.current, filterQuery);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return {
    result,
    error: store.error,
  };
}

/**
 * Retrieves a flow definition document from Firestore async.
 * @param flowDefinitionId - ID of the flow definition to retrieve.
 */
export async function getFlowDefinitionDoc(flowDefinitionId: UUID) {
  return await getDoc(
    doc(
      getFirestore(),
      getPathForFlowDefinitions(flowDefinitionId),
    ).withConverter(FlowDefinitionConverter),
  );
}

/**
 * Merges the base data with the update data.
 *
 * Array properties are replaced completely to allow for deletions.
 * This means `update` should contain all items, even if they are not updated.
 * Partial data is allowed in array items, but they are required to have `id` props
 * so they can be merged with the original items found in `base`.
 *
 * The function does _not_ perform validation.
 * @param base - All data from the flow definition document
 * @param update - Partial data to be merged with the base data
 * @returns Merged data
 * @internal
 */
export function prepareFlowDefinitionUpdatePayload(
  base: FlowDefinitionDto,
  update: FlowDefinitionUpdateDto,
): FlowDefinitionDto {
  const payload = mergeWith(base, update, (baseValue, updatedValue, key) => {
    const isCustomMergedArray =
      Array.isArray(baseValue) && Array.isArray(updatedValue);
    if (isCustomMergedArray) {
      // Arrays must be replaced for deletions to take effect.
      // But base data is still required because update data is partial.
      return updatedValue.map((updateItem) => {
        if ('id' in updateItem) {
          const baseItem = baseValue.find((item) => item.id === updateItem.id);
          // TODO maybe check that updateItem isn't partial if baseItem is undefined
          return baseItem
            ? {
                ...baseItem,
                ...updateItem,
              }
            : updateItem;
        } else {
          trace({
            level: 'error',
            data: {
              base,
              update,
              key,
              baseValue,
              updatedValue,
            },
            category: 'flow',
          });
          throw new Error('ID property is missing from array item');
        }
      });
    }
  });

  return payload;
}

/**
 * Updates a flow definition's basic settings (e.g. its title or description).
 *
 * User privileges aren't validated by this function.
 * @param updateDto - The payload of basic flow settings
 */
export async function updateFlowDefinitionDoc(
  updateDto: FlowDefinitionUpdateDto,
) {
  try {
    const ref = doc(
      getFirestore(),
      getPathForFlowDefinitions(updateDto.id),
    ).withConverter(FlowDefinitionConverter);

    const existingDoc = await getDoc(ref);
    if (!existingDoc.exists()) {
      throw new Error(
        `Flow definition at ${getPathForFlowDefinitions(
          updateDto.id,
        )} doesn't exist`,
      );
    }
    const existingData = existingDoc.data();

    await setDoc(
      ref,
      prepareFlowDefinitionUpdatePayload(existingData, updateDto),
      { merge: true },
    );
  } catch (error) {
    catchFirestoreError(error, { data: updateDto });
  }
}
