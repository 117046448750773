import { HSL, HslGradientColor } from './gradientUtils';

/**
 * A map of all valid brand colors except the gradients.
 */
export const brandColor = Object.freeze({
  purple: '#6366BA',
  blue: '#3A6ED2',
  dark: '#0D062D',
});

// TODO replace hard-coded brand color values in components

/**
 * The brand's most common gradient colors defined in various formats
 * so opacity can be applied to them without causing duplication.
 */
const gradHsl = HSL.fromHex(brandColor.purple).saturate(20);
export const brandGradient = new HslGradientColor(gradHsl, 334);
export const brandGradientDark = new HslGradientColor(gradHsl.darken(20), 334);
