import type { createVar } from '@vanilla-extract/css';
import { CSSProperties, PropsWithChildren } from 'react';
import { PositionUnit } from 'src/@types/dnd';
import FloatingDebug from '../debug/FloatingDebug';

interface IPositionedProps {
  /** The vertical position relative to the top edge of the canvas.
   * A higher number means the item appears at a lower position. */
  bottom?: number;
  className?: string;
  /** If `true`, renders a floating label that outputs the
   * item's position within the canvas. */
  debug?: boolean;
  /** The horizontal position relative to the left edge of the canvas. */
  left?: number;
  /** The horizontal position relative to the right edge of the canvas. */
  right?: number;
  style?: CSSProperties;
  /** The vertical position relative to the top edge of the canvas.
   * A higher number means the item appears at a lower position. */
  top?: number;
  /** Determines the CSS unit to be used for setting the coordinates. */
  unit: PositionUnit;
  /** If set, controls the z-index for this element. */
  z?: number | ReturnType<typeof createVar>;
}

/**
 * Positions the wrapped item using absolute positioning.
 *
 * TODO switch to using translations for better performance?
 * TODO support CSS units
 */
export function Positioned({
  bottom,
  children,
  className,
  debug,
  left,
  right,
  top,
  unit,
  style,
  z = 1,
}: PropsWithChildren<IPositionedProps>) {
  return (
    <div
      style={{
        ...style,
        display: 'inline-block',
        position: 'absolute',
        left: getCoordOrAuto(left, unit),
        right: getCoordOrAuto(right, unit),
        top: getCoordOrAuto(top, unit),
        bottom: getCoordOrAuto(bottom, unit),
        zIndex: z,
        willChange: 'left, right, top, bottom',
      }}
      className={className}
    >
      {children}
      {debug && (
        <FloatingDebug>
          {JSON.stringify({
            left: getCoordOrAuto(left, unit),
            right: getCoordOrAuto(right, unit),
            top: getCoordOrAuto(top, unit),
            bottom: getCoordOrAuto(bottom, unit),
            unit,
          })}
        </FloatingDebug>
      )}
    </div>
  );
}

const getCoordOrAuto = (rawCoord: number | undefined, unit: PositionUnit) =>
  typeof rawCoord === 'number' ? String(rawCoord) + unit : 'auto';
